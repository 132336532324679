<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>treasure-chest-open</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M21.334,12a2.968,2.968,0,0,0,.416-1.5,2.987,2.987,0,0,0-4.789-2.395,5.216,5.216,0,0,0-9.922,0A2.984,2.984,0,0,0,2.666,12Z"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="20.25"
      y1="2.25"
      x2="23.25"
      y2="2.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="21.75"
      y1="0.75"
      x2="21.75"
      y2="3.75"
    />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="0.75" y1="4.5" x2="3.75" y2="4.5" />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="2.25" y1="3" x2="2.25" y2="6" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M.75,12h22.5a0,0,0,0,1,0,0V22.25a1,1,0,0,1-1,1H1.75a1,1,0,0,1-1-1V12a0,0,0,0,1,0,0Z"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="5.25"
      y1="15"
      x2="5.25"
      y2="23.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="9.75"
      y1="17.25"
      x2="9.75"
      y2="23.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="14.25"
      y1="17.25"
      x2="14.25"
      y2="23.25"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="18.75"
      y1="15"
      x2="18.75"
      y2="23.25"
    />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="0.75" y1="15" x2="8.325" y2="15" />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="15.675"
      y1="15"
      x2="23.25"
      y2="15"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.75,14.25a3.75,3.75,0,0,1-7.5,0V12h7.5Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12,14.625a.375.375,0,1,1-.375.375A.375.375,0,0,1,12,14.625"
    />
  </svg>
</template>
